<div class="message-container text-center px-2 cbsw-success-booking-step">
  <div class="mb-1 cbsw-success-booking-message">
    <app-csw-text [text]="successTitleTemplate | translation: 'template_content': lang" customClass="fw-600"/>
  </div>

  <div class="cbsw-success-booking-sub-message">
    <app-csw-text [text]="successTemplate | translation: 'template_content': lang" />
  </div>

  <div class="mt-4">
    <app-button
      id="{{ 'booking-success-view-book-next-btn' }}"
      (clickEvent)="onAddtionalBooking()"
      [fontColor]="widgetService?.widgetConf?.button_font_color"
      [backgroundColor]="widgetService?.widgetConf?.button_background_color"
    >
      {{ 'booking_components.bookNextAppointment' | translate }}
    </app-button>
  </div>
</div>
