<div class="mt-3 cbsw-services-selection-step">
  @if (showErrorMessage) {
    <div>
      <app-alert
        [isAlertMessageTranslated]="true"
        type="danger"
        message="error_messages.no_services_found"
      />
    </div>
  }
  @if (showServiceGhostElement) {
    <ng-container *ngTemplateOutlet="ghostElementTemplate" />
  }

  @if (servicesGroupedData?.length > 0) {
    <div class="service-title">
      <app-csw-text
        customClass="step-element-title fw-600"
        [text]="serviceLabelTemplate | translation : 'template_content' : lang"
      />
    </div>
    @for (serviceGroupedData of servicesGroupedData; track serviceGroupedData) {
      <div class="category-title">
        <app-csw-text
          [text]="serviceGroupedData?.temp?.appointment_service_category | translation : 'name' : lang | calioSafe : 'html'"
          customClass="fw-600 fs-13"
        />
      </div>
      @if (!widgetService?.widgetConf?.context?.show_appointment_services_list) {
        <swiper [slidesPerView]="2" [spaceBetween]="20" [freeMode]="true">
          @for (serviceData of serviceGroupedData.value; track serviceData) {
          <ng-template swiperSlide>
            <div class="mt-3">
              <ng-container *ngTemplateOutlet="serviceDataTemplate; context: { serviceData: serviceData }" />
            </div>
          </ng-template>
          }
        </swiper>
      }
      @if (widgetService?.widgetConf?.context?.show_appointment_services_list) {
        <div class="row service-list-container">
          @for (serviceData of serviceGroupedData.value; track serviceData) {
            <div class="col-md-6 mt-3">
              <ng-container *ngTemplateOutlet="serviceDataTemplate; context: { serviceData: serviceData }" />
            </div>
          }
        </div>
      }
    }

    @if (widgetService.widgetConf?.context?.show_additional_services_provided_by_meeting_providers?.length && bookingService.partnerData.allow_multiple_services_bookings !== 1) {
      <div class="mt-3 cbsw-additional-services-selection-step">
        @if (showAdditionalServiceGhostElement) {
          <ng-container *ngTemplateOutlet="ghostElementTemplate" />
        } @else if (additionalServicesGroupedData?.length) {
          <div class="service-title cbw-additional-service-title">
            <app-csw-text
              customClass="step-element-title fw-600"
              [text]="additionalServiceLabelTemplate | translation : 'template_content' : lang"
            />
          </div>
          @for (serviceGroupedData of additionalServicesGroupedData; track serviceGroupedData) {
            <div class="category-title">
              <app-csw-text
                [text]="serviceGroupedData?.temp?.appointment_service_category | translation : 'name' : lang | calioSafe : 'html'"
                customClass="fw-600 fs-13"
              />
            </div>
            @if (!widgetService?.widgetConf?.context?.show_appointment_services_list) {
              <swiper [slidesPerView]="2" [spaceBetween]="20" [freeMode]="true">
                @for (serviceData of serviceGroupedData.value; track serviceData) {
                <ng-template swiperSlide>
                  <div class="mt-3">
                    <ng-container *ngTemplateOutlet="serviceDataTemplate; context: { serviceData: serviceData }" />
                  </div>
                </ng-template>
                }
              </swiper>
            }
            @if (widgetService?.widgetConf?.context?.show_appointment_services_list) {
              <div class="row service-list-container">
                @for (serviceData of serviceGroupedData.value; track serviceData) {
                  <div class="col-md-6 mt-3">
                    <ng-container *ngTemplateOutlet="serviceDataTemplate; context: { serviceData: serviceData }" />
                  </div>
                }
              </div>
            }
          }
        }

      </div>
    }

    @if (showServiceSelectionRequiredError) {
      <div class="cbsw-meeting-type-selection-setp-error cbsw-global-step-error">
        <div class="text-danger">
          <div>{{ 'error_messages.select_one_option' | translate }}</div>
        </div>
      </div>
    }

    <div class="d-flex justify-content-end mt-4 mb-4 pe-3 cbw-custom-navigation">
      <div class="me-2">
        <app-button
          id="{{ 'services-selection-previous-btn' }}"
          (clickEvent)="previous()"
          [fontColor]="widgetService?.widgetConf?.button_font_color"
          [backgroundColor]="widgetService?.widgetConf?.button_background_color"
        >
          <span class="custom-button-icon back"></span>
          {{ bookingService.widgetTemplates | calioMeetingTemplate : { id: 129, type: "template" } | translation : "template_content" : lang }}
        </app-button>
      </div>
      <div>
        <app-button
          id="{{ 'services-selection-next-btn' }}"
          (clickEvent)="next()"
          [fontColor]="widgetService?.widgetConf?.button_font_color"
          [backgroundColor]="widgetService?.widgetConf?.button_background_color"
        >
          {{ bookingService.widgetTemplates | calioMeetingTemplate : { id: 128, type: "template" } | translation : "template_content" : lang }}
          <span class="custom-button-icon next"></span>
        </app-button>
      </div>
    </div>
  }
</div>

<ng-template #serviceDataTemplate let-serviceData="serviceData">
  <app-info-card
    [showCursorPointer]="true"
    [showBody]="true"
    [showFooter]="true"
    [selectedCardColor]="widgetService?.widgetConf?.header_background_color"
    [isSelected]="isSelectedService(serviceData)"
    (cardClickEvent)="toggleServiceSelection(serviceData)"
  >
    <div cardBody>
      <div class="cbsw-service-name">
        <app-csw-text
          [text]="serviceData | translation : 'name' : lang | calioSafe : 'html'"
          customClass="fw-600 fs-13"
        />
      </div>
      @if (serviceData.description && bookingService.partnerData.show_inline_description === 1) {
        <div class="cbsw-service-description">
          <app-csw-text
            [text]="serviceData | translation : 'description' : lang"
            customClass="fs-13"
          />
        </div>
      }
      <div class="cbsw-service-duration">
        @if ((serviceData.duration | replaceComma | toHour) > 0) {
          <app-csw-text
            [text]="(serviceData.duration | replaceComma | toHour) + ('booking_components.durationHour' | translate)"
            customClass="fs-13"
          />
        }
        @if ((serviceData.duration | replaceComma | toMinute) > 0) {
          <app-csw-text
            [text]="(serviceData.duration | replaceComma | toMinute) + ('booking_components.durationMinute' | translate)"
            customClass="fs-13"
          />
        }
        @if (serviceData.price > 0) {
          <app-csw-text
            [text]="'/ ' + (serviceData.price | calioCurrency : bookingService.partnerData['currency'])"
            customClass="fs-13"
          />
          @if (serviceData?.tax?.rate > 0) {
            <small class="taxes text-muted">
              {{ "booking_components.taxes" | translate : { percentage: serviceData?.tax?.rate } }}
            </small>
          }
        }
        @if (serviceData.price === 0) {
          {{ "booking_components.free" | translate }}
        }
      </div>

      @if (serviceData.meeting_provider_id && serviceData.is_additional_service) {
        <div class="cbsw-service-meeting-provider">
          @if (serviceData.meeting_provider_id === 1) {
            <app-csw-text [text]="'booking_components.consulting_in_store' | translate" customClass="fs-13" />
          } @else if (serviceData.meeting_provider_id === 2) {
            <app-csw-text [text]="'booking_components.consulting_via_zoom' | translate" customClass="fs-13" />
          } @else if (serviceData.meeting_provider_id === 3) {
            <app-csw-text [text]="'booking_components.consulting_via_calenso_meeting' | translate" customClass="fs-13" />
          } @else if (serviceData.meeting_provider_id === 4) {
            <app-csw-text [text]="'booking_components.consulting_via_phone' | translate" customClass="fs-13" />
          } @else if (serviceData.meeting_provider_id === 5) {
            <app-csw-text [text]="'booking_components.consulting_on_location' | translate" customClass="fs-13" />
          } @else if (serviceData.meeting_provider_id === 6) {
            <app-csw-text [text]="'booking_components.consulting_via_unblu' | translate" customClass="fs-13" />
          } @else if (serviceData.meeting_provider_id === 7) {
            <app-csw-text [text]="'booking_components.consulting_via_microsoft_teams' | translate" customClass="fs-13" />
          } @else if (serviceData.meeting_provider_id === 8) {
            <app-csw-text [text]="'booking_components.consulting_via_google_meet' | translate" customClass="fs-13" />
          } @else if (serviceData.meeting_provider_id === 9) {
            <app-csw-text [text]="'booking_components.consulting_via_goto_meeting' | translate" customClass="fs-13" />
          } @else if (serviceData.meeting_provider_id === 10) {
            <app-csw-text [text]="'booking_components.consulting_via_webex' | translate" customClass="fs-13" />
          } @else if (serviceData.meeting_provider_id === 11) {
            <app-csw-text [text]="'booking_components.consulting_via_phone' | translate" customClass="fs-13" />
          } @else if (serviceData.meeting_provider_id === 13) {
            <app-csw-text [text]="'booking_components.consulting_via_metaverse' | translate" customClass="fs-13" />
          }
        </div>
      }

    </div>
    <div cardFooter>
      <div
        class="d-flex align-items-center justify-content-center"
        [ngClass]="{
          'justify-content-between': isSelectedService(serviceData)
        }"
      >
        <div class="d-flex align-items-center">
          @if (isSelectedService(serviceData)) {
            <app-csw-link
              [isLink]="false"
              [styleConfig]="{
                color: widgetService?.widgetConf?.header_background_color,
                textDecorationColor: widgetService?.widgetConf?.header_background_color
              }"
              [text]="'booking_components.deselect' | translate"
            />
          } @else {
            <app-csw-link
              [isLink]="false"
              [styleConfig]="{
                color: widgetService?.widgetConf?.header_background_color,
                textDecorationColor: widgetService?.widgetConf?.header_background_color
              }"
              [text]="'booking_components.select' | translate"
            />
          }
        </div>
        @if (isSelectedService(serviceData)) {
          <div class="next-arrow-container">
            <span (click)="next()">
              <img
                src="https://my.calenso.com/images/next_icon_widget.svg"
                width="26px"
                alt="next_icon_widget"
              />
            </span>
          </div>
        }
      </div>
    </div>
  </app-info-card>
</ng-template>

<ng-template #ghostElementTemplate>
  <div class="skeleton-box-card">
    @for (element of ghostElement; track element) {
    <div class="row mt-4">
      <div class="col-12 mb-3">
        <div class="heading-element"></div>
      </div>
      @for (element of ghostElement; track element) {
      <div class="col-6 mb-4">
        <div class="d-flex stats-card shadow">
          <div class="skeleton-animation w-100 d-flex flex-column">
            <div class="body-container">
              <div class="stat-box-1"></div>
              <div class="stat-box mt-2"></div>
            </div>
            <div class="border-top"></div>
          </div>
        </div>
      </div>
      }
    </div>
    }
  </div>
</ng-template>
