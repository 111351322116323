@if (bookingService?.selectedBookingData?.totalBookingAmount > 0) {
  <div class="mb-1">
    <app-csw-text
      [text]="((bookingService.widgetTemplates | calioMeetingTemplate: { id: 122, type: 'template' }) | translation: 'template_content': lang)"
      customClass="fw-600"
    />
  </div>
  <div class="mb-2">
    @if (isStripeEnabled) {
      <div class="d-flex align-items-center mt-1 radio-item">
        <input type="radio" class="payment-type-radio-button" value="creditcard"
          id="creditcard"
          (change)="onPaymentTypeChange('creditcard')"
          [(ngModel)]="paymentType" name="paymentType"
          [checked]="paymentType === 'creditcard' ? true: false"
        >
        <label class="radio-button-label mb-0" for="creditcard">{{ 'payment_method.creditcard' | translate }}</label>
      </div>
    }
    @if (isBexioEnabled) {
      <div class="d-flex align-items-center mt-1 radio-item">
        <input type="radio" class="payment-type-radio-button" value="invoice" id="invoice"
          (change)="onPaymentTypeChange('invoice')"
          [(ngModel)]="paymentType" name="paymentType"
          [checked]="paymentType === 'invoice' ? true: false"
        >
        <label class="radio-button-label mb-0" for="invoice">{{ 'payment_method.invoice' | translate }}</label>
      </div>
    }
    @if (isPaypalEnabled) {
      <div class="d-flex align-items-center mt-1 radio-item">
        <input type="radio" class="payment-type-radio-button" value="paypal"
          id="paypal-radio-btn"
          (change)="onPaymentTypeChange('paypal')" [(ngModel)]="paymentType" name="paymentType"
          [checked]="paymentType === 'paypal' ? true: false"
        >
        <label class="radio-button-label mb-0" for="paypal-radio-btn">{{ 'payment_method.paypal' | translate }}</label>
      </div>
    }
    @if (isStoreEnabled) {
      <div class="d-flex align-items-center mt-1 radio-item">
        <input type="radio" class="payment-type-radio-button" value="store" id="store"
          (change)="onPaymentTypeChange('store')" [(ngModel)]="paymentType" name="paymentType"
          [checked]="paymentType === 'store' ? true: false"
        >
        <label class="radio-button-label mb-0" for="store">
          {{ ((bookingService.widgetTemplates | calioMeetingTemplate: { id: 168, type: 'template' }) | translation: 'template_content': lang) }}
        </label>
      </div>
    }
    @if (isLexOfficePaymentTypeEnabled) {
      <div class="d-flex align-items-center mt-1 radio-item">
        <input type="radio" class="payment-type-radio-button" value="lex_office_invoice" id="lexoffice-radio-btn"
          (change)="onPaymentTypeChange('lex_office_invoice')" [(ngModel)]="paymentType" name="paymentType"
          [checked]="paymentType === 'lex_office_invoice' ? true : false"
        >
        <label class="radio-button-label mb-0" for="lexoffice-radio-btn">{{ 'payment_method.lexoffice' | translate }}</label>
      </div>
    }
  </div>
  <div class="mb-2">
    <app-csw-link
      [styleConfig]="{
        'color': widgetService?.widgetConf?.header_background_color,
        'textDecorationColor': widgetService?.widgetConf?.header_background_color
      }"
      [showUnderLine]="true"
      [isLink]="false"
      [text]="'booking_components.redeem_coupon' | translate"
      (clickEvent)="toggleCoupon()"
    />
  </div>
  @if (showCouponField) {
    <div class="d-flex flex-column align-items-start cw-stripe-box mb-2">
      <div class="stripe-title">
        {{ 'coupon_credit_card_info.apply_coupon' | translate }}:
      </div>
      <div class="stripe-field mt-2 w-100">
        <app-coupon-form />
      </div>
    </div>
  }
  @if (
    isStripeEnabled
    && paymentType === 'creditcard'
    && bookingService?.selectedBookingData?.totalBookingAmount > 0
  ) {
    <div class="d-flex flex-column align-items-start cw-stripe-box mb-2">
      <div class="stripe-title">
        {{ 'payment_method.creditcard' | translate }}:
      </div>
      <div class="stripe-field mt-1 w-100">
        <app-stripe />
      </div>
    </div>
  }
  @if (
    isPaypalEnabled
    && paymentType === 'paypal'
    && bookingService?.selectedBookingData?.totalBookingAmount > 0
  ) {
    <div class="cw-paypal-box mt-4">
      @if (showPaypalLoader) {
        <div>
          <div class="d-flex flex-column justify-content-center align-items-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="mt-2">
              Resetting Paypal
            </div>
          </div>
        </div>
      }
      @if (!showPaypalLoader) {
        <app-cw-paypal
          [paypalSetting]="paypalSetting"
          [price]="bookingService?.selectedBookingData?.totalBookingAmount"
          [discount]="bookingService?.selectedBookingData?.totalDiscount"
        />
      }
    </div>
  }
}

@if (bookingService.selectedBookingData.captcha) {
  <div class="mb-2">
    <label class="input-label cbw-captcha-label">
      <span [innerHTML]="((bookingService.widgetTemplates | calioMeetingTemplate: { id: 242, type: 'template' }) | translation: 'template_content': lang | addRequired) | calioSafe: 'html'"></span>
    </label>
    <div>
      <img [src]="'data:image/png;base64,' + bookingService.selectedBookingData.captcha.captcha" alt="captcha" class="captcha-img cbw-captcha-img" />
    </div>
    <div class="pt-3 captcha-input-width">
      <div class="d-flex align-items-center justify-content-center gap-2">
        <input
          id="captcha"
          type="text"
          required
          name="captcha"
          #captcha="ngModel"
          placeholder="{{ 'captcha.placeholder' | translate }}"
          maxlength="6"
          minlength="6"
          spellcheck="false"
          [(ngModel)]="captchaInput"
          class="form-control cbw-input-field cbw-input-captcha-field"
        >
        @if (validatingCaptchaChallenge) {
          <div  class="d-inline-flex">
            <mat-icon class="spin-animation" svgIcon="calio-spinner-icon" />
          </div>
        }
      </div>
      @if (captcha.touched && !captcha?.valid) {
        <div class="alert alert-danger captcha-warning cbw-captcha-error">
          @if (captcha.errors?.required) {
            <div>
              {{ 'captcha.required' | translate }}
            </div>
          }
          @if (captcha.errors?.minlength) {
            <div>
              {{ 'captcha.invalid' | translate }}
            </div>
          }
          @if (captcha.errors?.invalidCaptcha) {
            <div>
              {{ 'captcha.invalid' | translate }}
            </div>
          }
        </div>
      }
      @if (captcha.touched && captcha?.valid) {
        <div class="alert alert-success captcha-warning cbw-captcha-success">
          <div>
            {{ 'captcha.valid' | translate }}
          </div>
        </div>
      }
    </div>
  </div>
}

@if (showError) {
  <div class="alert alert-danger mt-3 mb-0">
    <div class="error-title">{{ errorTitle | translate }}</div>
    <div>{{ errorMessage }}</div>
  </div>
}

<div class="d-flex flex-column flex-md-row justify-content-md-between mt-4 mb-4">
  <div>
    @if (bookingService.partnerData.is_cart_supported === 1) {
      <app-button
        id="{{ 'payment-method-book-additional-service-btn' }}"
        customClass="w-100"
        [isDisabled]="disableAllButton"
        (clickEvent)="bookAdditionalService()"
        [fontColor]="widgetService?.widgetConf?.button_font_color"
        [backgroundColor]="widgetService?.widgetConf?.button_background_color"
      >
        {{ 'booking_components.add_service' | translate }}
      </app-button>
    }
  </div>

  <div class="d-flex flex-column flex-md-row justify-content-md-end cbw-custom-navigation">
    <div class="me-md-2 my-2 my-md-0">
      <app-button
        id="{{ 'payment-method-previous-btn' }}"
        customClass="w-100"
        (clickEvent)="previous()"
        [isDisabled]="disableAllButton"
        [fontColor]="widgetService?.widgetConf?.button_font_color"
        [backgroundColor]="widgetService?.widgetConf?.button_background_color"
      >
        <span class="custom-button-icon back"></span>
        {{ ((bookingService.widgetTemplates | calioMeetingTemplate: { id: 129, type: 'template' }) | translation: 'template_content': lang )}}
      </app-button>
    </div>

    <div>
      <app-button
        id="{{ 'payment-method-book-btn' }}"
        customClass="w-100"
        (clickEvent)="book()"
        [isDisabled]="disableAllButton"
        [fontColor]="widgetService?.widgetConf?.button_font_color"
        [backgroundColor]="widgetService?.widgetConf?.button_background_color"
      >
        <span
          [innerHTML]="((bookingService.widgetTemplates | calioMeetingTemplate: { id: 130, type: 'template' }) | translation: 'template_content': lang)"
        ></span>
        <span class="custom-button-icon next"></span>
      </app-button>
    </div>
  </div>
</div>



