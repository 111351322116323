@if (workers?.length) {
  <form #workerForm="ngForm">
    <div class="cbsw-workers-selection-step">
      <!-- Show this section if worker length is greater than 10 -->
      @if (workersView === workersViews.DROPDOWN) {
      <div class="row">
        <div class="col-md-12">
          <label>
            <app-csw-text
              [text]="widgetResourceHeadingTemplate | translation : 'template_content' : lang"
              customClass="step-element-title fw-600"
            />
          </label>
          <ng-select
            id="store"
            name="store"
            placeholder=""
            [multiple]="false"
            [(ngModel)]="selectedWorkerId"
            (change)="onWorkerChange($event)"
            [required]="true"
            #workerControl="ngModel"
            [ngClass]="{
              'is-invalid': workerControl.touched && workerControl.invalid
            }"
          >
            @if (widgetService?.widgetConf?.partner?.egal_wer_show === 1) {
              <ng-option [value]="0">
                <div class="d-flex align-items-center">
                  <div class="avatar">
                    <img
                      class="default-avatar rounded-circle"
                      src="{{ baseUrl }}/img/sample-avatar.png"
                      alt=""
                    />
                  </div>
                  <div class="description">
                    <div class="cbw-title">
                      {{ widgetAnyoneLabelTemplate | translation : "template_content" : lang }}
                    </div>
                    <div class="cbw-function">
                      {{ widgetAnyoneDescTemplate | translation : "template_content" : lang }}
                    </div>
                  </div>
                </div>
              </ng-option>
            }
            @for (worker of workers; track worker.uuid) { @if (worker.id != null) {
              <ng-option [value]="worker.id">
                <div class="d-flex align-items-center">
                  <div class="avatar">
                    @if (worker.avatar !== null) {
                      <img
                        #image
                        class="resource-avatar rounded-circle"
                        src="{{ workerAvatarBaseUrl }}{{ worker.uuid }}/thumb_{{ worker.avatar }}"
                        (error)="image.src = baseUrl + '/img/sample-avatar.png'"
                        alt=""
                      />
                    }
                    @if (worker.avatar === null) {
                      <img
                        class="default-avatar rounded-circle"
                        src="{{ baseUrl }}/img/sample-avatar.png"
                        alt=""
                      />
                    }
                  </div>
                  <div class="description">
                    <div class="cbw-title">
                      @if (worker.is_resource === 0) {
                        {{ worker.resource_name }}
                      }
                      @if (worker.is_resource === 1) {
                        {{ worker | translation : "booking_label" : lang }}
                      }
                    </div>
                    @if (worker.is_resource === 0) {
                      <div class="cbw-function">
                        {{ worker | translation : "job_title" : lang }}
                      </div>
                    }
                    @if (worker.is_resource !== 0) {
                      <div class="cbw-function">
                        {{ worker | translation : "description" : lang }}
                      </div>
                    }
                    @if (widgetShowResourceStoreName === 1) {
                      <div class="cbw-worker-store-location">
                        <span class="cbw-worker-store-location-name">{{ worker.store | translation : "name" : lang }}</span>
                        @if (worker.store.is_online === 0) {
                          <span class="cbw-worker-store-location-separator">,&nbsp;</span>
                          <span class="cbw-worker-store-location-address">
                            @if (worker.store.street) {
                              {{ worker.store.street }},&nbsp;
                            }
                            @if (worker.store.zip) {
                              {{ worker.store.zip }}&nbsp;
                            }
                            @if (worker.store.city) {
                              {{ worker.store.city }}
                            }
                            @if (worker.store.country) {
                              ,&nbsp;{{ worker.store.country | translation : "name" : lang }}
                            }
                          </span>
                        }
                      </div>
                    }
                  </div>
                </div>
              </ng-option>
            }
          }
          </ng-select>
          @if (workerControl.touched && !workerControl.valid) {
            <div class="text-danger">
              @if (workerControl.errors.required) {
                <div>
                  {{ "error_messages.required_error_message" | translate }}
                </div>
              }
            </div>
          }
        </div>
      </div>
      }
      <!-- Show this section if worker length is less than 10 -->
      @if (workersView === workersViews.CARD) {
      <div>
        <app-csw-text
          [text]="widgetResourceHeadingTemplate | translation : 'template_content' : lang"
          customClass="step-element-title fw-600"
        />
      </div>
      <div class="row cbsw-workers-selection">
        @if (workers?.length > 1 &&
        widgetService?.widgetConf?.partner?.egal_wer_show === 1)
        {
          <div
            class="worker-slot-card col-md-6 col-lg-4 mt-3"
            (click)="selectedWorkerId = 0; onWorkerChange($event)"
          >
            <app-info-card
              [showCardBorder]="true"
              [showShadow]="false"
              [showBody]="true"
              [isSelected]="selectedWorkerId === 0"
              [selectedCardColor]="widgetService?.widgetConf?.header_background_color"
            >
              <div cardBody>
                <div class="d-flex align-items-center justify-content-between cbsw-workers-avatar-wrapper">
                  <div class="d-flex align-items-center cbsw-workers-avatar-sub-wrapper">
                    <div class="avatar">
                      <img
                        class="default-avatar rounded-circle"
                        src="{{ baseUrl }}/img/sample-avatar.png"
                        height="200"
                        alt=""
                      />
                    </div>
                    <div class="description">
                      <div class="cbw-title">
                        {{ widgetAnyoneLabelTemplate | translation : "template_content" : lang }}
                      </div>
                      <div class="cbw-function">
                        {{ widgetAnyoneDescTemplate | translation : "template_content" : lang }}
                      </div>
                    </div>
                  </div>
                  <div class="next-arrow-container">
                    @if (selectedWorkerId === 0) {
                      <span (click)="next()">
                        <div class="next-button-wrapper">
                          <img
                            src="https://my.calenso.com/images/next_icon_widget.svg"
                            width="26px"
                            alt=""
                          />
                        </div>
                      </span>
                    }
                  </div>
                </div>
              </div>
            </app-info-card>
          </div>
        }
        @for (worker of workers; track worker) {
          @if (worker.id != null) {
          <div
            class="worker-slot-card mt-3 col-md-6 col-lg-4"
            (click)="selectedWorkerId = worker.id; onWorkerChange($event)"
          >
            <app-info-card
              [showCardBorder]="true"
              [showShadow]="false"
              [showBody]="true"
              [isSelected]="selectedWorkerId === worker.id"
              [selectedCardColor]="widgetService?.widgetConf?.header_background_color"
              [customCardClass]="selectedWorkerId === worker?.id ? 'cbsw-next-icon' : ''"
            >
              <div cardBody>
                <div class="d-flex align-items-center justify-content-between cbsw-workers-avatar-wrapper">
                  <div class="d-flex align-items-center cbsw-workers-avatar-sub-wrapper">
                    <div class="avatar">
                      @if (worker.avatar !== null) {
                        <img
                          #image
                          class="resource-avatar rounded-circle"
                          src="{{ workerAvatarBaseUrl }}{{ worker.uuid }}/thumb_{{ worker.avatar }}"
                          (error)="
                            image.src = baseUrl + '/img/sample-avatar.png';
                            image.classList.add('default-avatar-margin')
                          "
                          height="200"
                          alt=""
                        />
                      }
                      @if (worker.avatar === null) {
                        <img
                          class="default-avatar rounded-circle default-avatar-margin"
                          src="{{ baseUrl }}/img/sample-avatar.png"
                          height="200"
                          alt=""
                        />
                      }
                    </div>
                    <div class="description">
                      @if (worker?.store?.name) {
                        <div class="cbw-store-name">
                          @if (worker?.store?.is_multi_language === 1) {
                            {{ worker?.store | translation : "name" : lang }}
                          }
                          @if (worker?.store?.is_multi_language === 0) {
                            {{ worker?.store?.name }}
                          }
                        </div>
                      }
                      <div class="cbw-title">
                        @if (worker.is_resource === 0) {
                          {{ worker.resource_name }}
                        }
                        @if (worker.is_resource === 1) {
                          @if (worker.is_multi_language === 1) {
                            {{ worker | translation : "booking_label" : lang }}
                          }
                          @if (worker.is_multi_language === 0) {
                            {{ worker.resource_name }}
                          }
                        }
                      </div>
                      @if (worker.is_resource === 0) {
                        <div class="cbw-function title">
                          @if (worker.is_multi_language === 1) {
                            <span [innerHTML]="worker | translation : 'job_title' : lang | calioSafe : 'html'"></span>
                          }
                          @if (worker.is_multi_language === 0) {
                            <span [innerHTML]="worker.job_title | calioSafe : 'html'"></span>
                          }
                        </div>
                      }
                      @if (worker.is_resource !== 0) {
                        <div class="cbw-function description">
                          @if (worker.is_multi_language === 1) {
                            <span [innerHTML]="worker | translation : 'description' : lang | calioSafe : 'html'"></span>
                          }
                          @if (worker.is_multi_language === 0) {
                            <span [innerHTML]="worker.description | calioSafe : 'html'"></span>
                          }
                        </div>
                      }
                      <!-- Showing description for custom AMAG smart widget, By defaut it will be display:none-->
                      @if (worker?.store?.logo) {
                        <div class="cbw-custom-store-logo">
                          <img
                            src="{{ storeBaseUrl }}{{ worker.store.uuid }}/{{ worker?.store?.logo }}"
                            height="40"
                          />
                        </div>
                      }
                      <!-- Showing description for custom AMAG smart widget, By defaut it will be display:none-->
                      @if (worker?.description) {
                      <div class="cbw-custom-description">
                        @if (worker.is_multi_language === 1) {
                          <span [innerHTML]="worker | translation : 'description' : lang | calioSafe : 'html'"></span>
                        } @if (worker.is_multi_language === 0) {
                          <span [innerHTML]="worker.description | calioSafe : 'html'"></span>
                        }
                      </div>
                      }
                      @if (widgetShowResourceStoreName === 1) {
                        <div class="cbw-worker-store-location">
                          <span class="cbw-worker-store-location-name">
                            {{ worker.store | translation : "name" : lang }}
                          </span>
                          @if (worker.store.is_online === 0) {
                            <span class="cbw-worker-store-location-separator">,&nbsp;</span>
                            <span class="cbw-worker-store-location-address">
                              @if (worker.store.street) {
                                {{ worker.store.street }},&nbsp;
                              }
                              @if (worker.store.zip) {
                                {{ worker.store.zip }}&nbsp;
                              }
                              @if (worker.store.city) {
                                {{ worker.store.city }}
                              }
                              @if (worker.store.country) {
                                ,&nbsp;{{ worker.store.country | translation : "name" : lang }}
                              }
                            </span>
                          }
                        </div>
                      }
                    </div>
                  </div>
                  <div class="next-arrow-container">
                    @if (selectedWorkerId === worker.id) {
                      <span (click)="next()">
                        <div class="next-button-wrapper">
                          <img
                            src="https://my.calenso.com/images/next_icon_widget.svg"
                            width="26px"
                          />
                        </div>
                      </span>
                    }
                  </div>
                </div>
              </div>
            </app-info-card>
          </div>
          }
        }
      </div>
      }

      @if (showWorkerSelectionRequiredError) {
        <div class="cbsw-meeting-type-selection-setp-error cbsw-global-step-error">
          <div class="text-danger">
            <div>{{ 'error_messages.select_one_option' | translate }}</div>
          </div>
        </div>
      }

      <div class="d-flex justify-content-end mt-4 mb-4 cbw-custom-navigation">
        <div class="me-2">
          <app-button
            id="{{ 'workers-selection-previous-btn' }}"
            (clickEvent)="previous()"
            [fontColor]="widgetService?.widgetConf?.button_font_color"
            [backgroundColor]="widgetService?.widgetConf?.button_background_color"
          >
            <span class="custom-button-icon back"></span>
            {{ bookingService.widgetTemplates | calioMeetingTemplate : { id: 129, type: "template" } | translation : "template_content" : lang }}
          </app-button>
        </div>
        <div>
          <app-button
            id="{{ 'workers-selection-next-btn' }}"
            (clickEvent)="next()"
            [fontColor]="widgetService?.widgetConf?.button_font_color"
            [backgroundColor]="widgetService?.widgetConf?.button_background_color"
          >
            {{ bookingService.widgetTemplates | calioMeetingTemplate : { id: 128, type: "template" } | translation : "template_content" : lang }}
            <span class="custom-button-icon next"></span>
          </app-button>
        </div>
      </div>
    </div>
  </form>
}
