export const WIDGET_TYPE = {
  LEAD_GENERATOR: 1,
  SURVEY: 2,
  BOOKING: 3,
  // FILL_CALENDAR: 4,
};

export const TRIGGER_TYPE = {
  IMMEDIATELY: 1
};

export const BOTTOM_TYPES = {
  BOTTOM_RIGHT: 'bottom-right',
  BOTTOM_LEFT: 'bottom-left',
};

export const CUSTOM_FIELD_TYPES = {
  CUSTOM_FIELD_SELECT: 'select',
  CUSTOM_FIELD_TEXT: 'text',
  CUSTOM_FIELD_TEXT_AREA: 'textarea',
  CUSTOM_FIELD_CHECKBOX: 'checkbox',
  CUSTOM_FIELD_FILE: 'file',
  CUSTOM_FIELD_RADIO: 'radio',
  CUSTOM_FIELD_DATE: 'date',
  CUSTOM_FIELD_INFORMATION: 'information',
  CUSTOM_FIELD_NUMBER_SELECT_CARD: 'number-select',
  CUSTOM_FIELD_IMAGE_SELECT_CARD: 'image-select',
  CUSTOM_FIELD_SELECT_CARD: 'card-select',
  CUSTOM_FIELD_WORKER_SELECT: 'worker-select',
};

export const BOOKING_PAGE_VIEWS = {
  STORE_VIEW: 'STORE_VIEW',
  MEETING_TYPE_VIEW: 'MEETING_TYPE_VIEW',
  SERVICES_VIEW: 'SERVICES_VIEW',
  WORKERS_VIEW: 'WORKERS_VIEW',
  SLOTS_VIEW: 'SLOTS_VIEW',
  PERSONAL_FORM_VIEW: 'PERSONAL_FORM_VIEW',
  BOOKING_SUMMARY_VIEW: 'BOOKING_SUMMARY_VIEW',
  BOOKING_SUCCESS_VIEW: 'BOOKING_SUCCESS_VIEW',
  BOOKING_FAILED_VIEW: 'BOOKING_FAILED_VIEW',
  BOOKING_QUESTIONS_VIEW_BEFORE: 'BOOKING_QUESTIONS_VIEW_BEFORE',
  BOOKING_QUESTIONS_VIEW_AFTER: 'BOOKING_QUESTIONS_VIEW_AFTER'
} as const;

export const SALUTATION_TYPES = [
  { display: 'mr', value: 'm' },
  { display: 'ms', value: 'f' },
  { display: 'other', value: 'o' }
];

export const WIDGET_TEMPLATES_CONST = {
  WIDGET_BOOKING_INFO_APPOINTMENT: 'widget_booking_info_appointment',
  WIDGET_BOOKING_INFO_EVENT: 'widget_booking_info_event',
  WIDGET_NEXT: 'widget_next_button',
  WIDGET_PREVIOUS: 'widget_previous_button',
  WIDGET_BOOK_BUTTON: 'widget_book_button',
  WIDGET_APPOINTMENT_STORE_LABEL: 'widget_store_label',
  WIDGET_APPOINTMENT_SERVICE_LABEL: 'widget_choose_appointment_service_label',
  ADDITIONAL_SERVICES_BY_ANOTHER_MEETING_PROVIDER: 'additional_services_by_another_meeting_provider',
  WIDGET_DATE_LABEL: 'widget_choose_desired_date_label',
  WIDGET_NAV_SERVICE_LABEL: 'widget_navigation_selection_label',
  WIDGET_NAV_RESOURCE_LABEL: 'widget_navigation_resource_label',
  WIDGET_NAV_DATE_LABEL: 'widget_navigation_date_label',
  WIDGET_NAV_SUMMARY_LABEL: 'widget_navigation_summary_label',
  WIDGET_MORNING_LABEL: 'widget_morning_label',
  WIDGET_FORENOON_LABEL: 'widget_forenoon_label',
  WIDGET_NOON_LABEL: 'widget_noon_label',
  WIDGET_AFTERNOON_LABEL: 'widget_afternoon_label',
  WIDGET_EVENING_LABEL: 'widget_evening_label',
  WIDGET_NIGHT_LABEL: 'widget_night_label',
  WIDGET_RESOURCE_LABEL: 'widget_resource_label',
  WIDGET_ANYONE_LABEL: 'widget_anyone_label',
  WIDGET_ANYONE_DESC_LABEL: 'widget_anyone_description_label',
  WIDGET_NO_BOOKABLE_DATES_LABEL: 'widget_no_appointents_found',
  WIDGET_YES_SEARCH_BUTTON_LABEL: 'widget_no_appointents_found_button',
  WIDGET_IN_STORE_LABEL: 'widget_in_store_label',
  WIDGET_ANY_STORES: 'widget_any_stores',
  WIDGET_APPOINTMENT_SUCCESS_TITLE: 'widget_success_page_header_text'
};

export const GTM_EVENTS = {
  // appointment
  appointment_booking_start: 'appointment_booking_start',
  appointment_booking_step_questions_start: 'appointment_booking_step_questions_start',
  appointment_booking_step_questions_end: 'appointment_booking_step_questions_end',
  appointment_booking_step_meetings_start: 'appointment_booking_step_meetings_start',
  appointment_booking_step_meetings_end: 'appointment_booking_step_meetings_end',
  appointment_booking_step_stores_start: 'appointment_booking_step_stores_start',
  appointment_booking_step_stores_end: 'appointment_booking_step_stores_end',
  appointment_booking_step_services_start: 'appointment_booking_step_services_start',
  appointment_booking_step_services_end: 'appointment_booking_step_services_end',
  appointment_booking_step_resources_start: 'appointment_booking_step_resources_start',
  appointment_booking_step_resources_end: 'appointment_booking_step_resources_end',
  appointment_booking_step_available_appointments_start: 'appointment_booking_step_available_appointments_start',
  appointment_booking_step_available_appointments_end: 'appointment_booking_step_available_appointments_end',
  appointment_booking_step_personal_data_start: 'appointment_booking_step_personal_data_start',
  appointment_booking_step_personal_data_end: 'appointment_booking_step_personal_data_end',
  appointment_booking_step_summary: 'appointment_booking_step_summary',
  appointment_booking: 'appointment_booking',
  appointment_booking_step_success: 'appointment_booking_step_success',
  appointment_booking_step_failed: 'appointment_booking_step_failed',

  // survey
  survey_start_step: 'survey_start_step',
  other_survey_steps: 'survey_step_',
  survey_last_step: 'survey_last_step',
};

export const EMAIL_REGEX =
  /^[\p{L}0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[\p{L}0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[_\p{L}0-9][-_\p{L}0-9]*\.)*(?:[\p{L}0-9][-\p{L}0-9]{0,62})\.(?:(?:[a-z]{2}\.)?[a-z]{2,})$/iu;
export const ZIP_REGEX = /^((\d{4} [A-Z]{2})|(\d{3,6})|(\d{5}(-\d{4})?)|([A-Z0-9]{1,4} [A-Z0-9]{1,4}))$/;

export const LANGUAGES = [
  {
    text: 'german',
    value: 'de',
    secondary_text: 'DE',
    locale: 'de_CH',
    flagIcon: '/assets/images/flags/if_Flag_of_Switzerland_-_Circle.png',
  },
  {
    text: 'french',
    value: 'fr',
    secondary_text: 'FR',
    locale: 'fr_CH',
    flagIcon: '/assets/images/flags/if_Flag_of_France_-_Circle.png',
  },
  {
    text: 'italian',
    value: 'it',
    secondary_text: 'IT',
    locale: 'it_CH',
    flagIcon: '/assets/images/flags/if_Flag_of_Italy_-_Circle.png',
  },
  {
    text: 'english',
    value: 'en',
    secondary_text: 'EN',
    locale: 'en_US',
    flagIcon: '/assets/images/flags/if_Flag_of_United_States_-_Circle.png',
  },
  {
    text: 'dutch',
    value: 'nl',
    secondary_text: 'NL',
    locale: 'nl_NL',
    flagIcon: '/assets/images/flags/dutch.png',
  },
  {
    text: 'finnish',
    value: 'fi',
    secondary_text: 'FI',
    locale: 'fi_FI',
    flagIcon: '/assets/images/flags/finnish.png',
  },
  {
    text: 'polish',
    value: 'pl',
    secondary_text: 'PL',
    locale: 'pl_PL',
    flagIcon: '/assets/images/flags/polish.png',
  },
  {
    text: 'spanish',
    value: 'es',
    secondary_text: 'ES',
    locale: 'es_ES',
    flagIcon: '/assets/images/flags/spanish.png',
  },
  {
    text: 'swedish',
    value: 'sv',
    secondary_text: 'SE',
    locale: 'sv_SE',
    flagIcon: '/assets/images/flags/swedish.png',
  },
];

export const CUSTOMER_CSTM_FIELD_TITLE_LIST = [
  { id: 0, name: '--' },
  { id: 1, name: 'Dr.' },
  { id: 2, name: 'Dr. med.' },
  { id: 3, name: 'Prof.' }
];

export const WORKERS_VIEW = {
  CARD: "card",
  DROPDOWN: "dropdown"
};

export const PREFERRED_COUNTRIES = ['ch', 'de', 'fr', 'gb', 'it', 'nl', 'fi', 'pl', 'es', 'se', 'at'];

export const CUSTOM_MAT_ICONS = [
  { iconName: 'chevron-left-solid', iconPath: 'chevron-left-solid.svg' },
  { iconName: 'chevron-right-solid', iconPath: 'chevron-right-solid.svg' },
  { iconName: 'minus-solid', iconPath: 'minus-solid.svg' },
  { iconName: 'plus-solid', iconPath: 'plus-solid.svg' },
  { iconName: 'calendar-regular', iconPath: 'calendar-regular.svg' },
  { iconName: 'check-solid', iconPath: 'check-solid.svg' },
  { iconName: 'clock-regular', iconPath: 'clock-regular.svg' },
  { iconName: 'map-marker-alt-solid', iconPath: 'map-marker-alt-solid.svg' },
  { iconName: 'times-solid', iconPath: 'times-solid.svg' },
];

export const MIME_TYPES = {
  IMAGE: 'image/jpeg,image/jpe,image/png,image/gif',
  DOC: 'application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

export const MEETING_TYPE_ID = {
  STORE: 1,
  ZOOM: 2,
  CALENSO: 3,
  PHONE: 4,
  CUSTOMER: 5,
  UNBLU: 6,
  MICROSOFT_TEAMS: 7,
  GOOGLE_MEET: 8,
  GOTO_MEETING: 9,
  WEBEX: 10,
  WILL_CALL_YOU: 11,
  GROUP_APPOINTMENT_PLACE: 12,
  METAVERSE: 13
};

export const CALENSO_SCROLL_EVENT = 'calenso_scroll_to';

export const INTL_TEL_INPUT = {
  UTIL_SCRIPTS: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/24.0.0/js/utils.js',
  IPLOOKUP_URL: 'https://ipinfo.io/json?token=e7e53bffbd5b01'
};

export const SECTION_PRECEDENCE = {
  BOOKING_QUESTIONS_VIEW_BEFORE: 1,
  MEETING_TYPE_VIEW: 2,
  STORE_VIEW: 3,
  SERVICES_VIEW: 4,
  WORKERS_VIEW: 5,
  SLOTS_VIEW: 6,
  BOOKING_QUESTIONS_VIEW_AFTER: 7,
  PERSONAL_FORM_VIEW: 8,
  BOOKING_SUMMARY_VIEW: 9,
  BOOKING_SUCCESS_VIEW: 10,
  BOOKING_FAILED_VIEW: 11
};
