import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BOOKING_PAGE_VIEWS, GTM_EVENTS } from '@constants/app.constants';
import { environment } from '@environments/environment';
import { MeetingTypesModel } from '@models/meeting-types.model';
import { CurrentViewData } from '@models/widget-conf.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalioMeetingTemplatePipe } from '@pipes/calio-meeting-template.pipe';
import { CalioSafePipe } from '@pipes/calio-safe.pipe';
import { CalioTranslationPipe } from '@pipes/calio-translation.pipe';
import { BookingService } from '@services/feature/booking.service';
import { WidgetService } from '@services/feature/widget.service';
import { GoogleAnalyticsService } from '@services/utils/google-analytics.service';
import { LoggerService } from '@services/utils/logger.service';
import { ButtonComponent } from '@ui-lib/buttons/button/button.component';
import { InfoCardComponent } from '@ui-lib/cards/info-card/info-card.component';
import { CswTextComponent } from '@ui-lib/typography/csw-text/csw-text.component';


@Component({
  selector: 'app-meeting-type-selection',
  templateUrl: './meeting-type-selection.component.html',
  styleUrls: ['./meeting-type-selection.component.scss'],
  standalone: true,
  imports: [FormsModule, CswTextComponent, InfoCardComponent, ButtonComponent, CalioSafePipe, CalioTranslationPipe, CalioMeetingTemplatePipe, TranslateModule]
})
export class MeetingTypeSelectionComponent implements OnInit {

  readonly imagePath = environment.hostName;

  selectedMeetingTypeId: number;
  selectedMeetingType: MeetingTypesModel;
  meetingTypes = new Array<MeetingTypesModel>();
  lang: string;
  slotGhostElements = new Array<number>(7);
  showGhostElement = true;
  showMeetingTypeSelectionRequiredError = false;
  isGTMEventSent: boolean;

  @Output() gotoPage = new EventEmitter<CurrentViewData>();
  @Output() previousPageEvent = new EventEmitter<CurrentViewData>();

  constructor(
    public translate: TranslateService,
    public widgetService: WidgetService,
    public bookingService: BookingService,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {
  }

  ngOnInit(): void {
    this.lang = this.translate.getDefaultLang();
    this.translate.onLangChange.subscribe(language => this.lang = language.lang);
    this.isGTMEventSent = false;
    this.bookingService.loadAppointmentStateFromLocalStorage();
    const config = this.widgetService.widgetConf;
    this.getMeetingTypes(config?.context?.meeting_providers);
  }

  getMeetingTypes(ids: number[]): void {
    if (!ids || ids?.length === 0) {
      LoggerService.log('Meeting type proviers are not available');
      return;
    }
    this.bookingService.getMeetingTypesByIds(ids).subscribe({
      next: meetingTypes => {
        this.showGhostElement = false;
        if (meetingTypes) {
          this.meetingTypes = meetingTypes;
          if (this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedMeetingTypeId) {
            this.selectedMeetingType = this.meetingTypes.find(meeting => meeting.id === Number(this.bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedMeetingTypeId));
            this.selectedMeetingTypeId = this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedMeetingTypeId;
            if (this.widgetService.isDefaultMeetingTypeIdSelected()) {
              if (this.bookingService.lastView === BOOKING_PAGE_VIEWS.SERVICES_VIEW) {
                if (this.widgetService?.widgetConf?.context?.show_questions_before_booking_process) {
                  this.previous();
                }
              } else {
                this.onMeetingTypeChange(this.selectedMeetingTypeId, false);
                this.next(false);
              }
            }
          } else {
            if (this.meetingTypes?.length === 1) {
              this.onMeetingTypeChange(this.meetingTypes[0].id, false);
              this.next(false);
            }
          }
        } else {
          // TODO no meeting types available handling
          this.showGhostElement = false;
          this.meetingTypes = [];
        }

      },
      error: (error: HttpErrorResponse) => LoggerService.log(error)
    });
  }

  previous(): void {
    if (this.isGTMEventSent) {
      this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_meetings_end);
    }
    this.previousPageEvent.emit({ view: BOOKING_PAGE_VIEWS.BOOKING_QUESTIONS_VIEW_BEFORE, isUserInteracted: true });
  }

  next(isUserInteracted = true): void {

    // Meeting type is is not selected and clicked on next then show error.
    if (!this.selectedMeetingTypeId) {
      this.showMeetingTypeSelectionRequiredError = true;
      return
    }

    if (isUserInteracted) {
      // trigger start event on first user interaction happen and
      // meeting type selection step is configured to first step and
      // meeting types are more then 1
      this.bookingService.triggerApoointmentStartEvent();

      if (!this.isGTMEventSent) {
        this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_meetings_start);
        this.isGTMEventSent = true;
      }

      this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_meetings_end);
    }
    LoggerService.log('Goto next page');
    if (this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedMeetingTypeId === this.selectedMeetingType.id) {
      this.bookingService.selectedBookingData.selectedMeetingTypes.push(this.selectedMeetingType);
    } else {
      this.bookingService.selectedBookingData.currentSelectedAppointmentState = this.bookingService.resetCurrentSelectedAppointmentStateonStoreSelection();
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedMeetingTypeId = this.selectedMeetingType.id;
      this.bookingService.selectedBookingData.selectedMeetingTypes.push(this.selectedMeetingType);
    }

    if (
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedMeetingTypeId !== 1 &&
      !this.bookingService.selectedBookingData.currentSelectedAppointmentState.storeAndWorkerPreSelectedThroughWorkerSelectField
    ) {
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreId = undefined;
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStore = undefined;
    }

    this.bookingService.setAppointmentStateInLocalStorage(
      this.bookingService.selectedBookingData.currentSelectedAppointmentState
    );
    if (this.selectedMeetingTypeId === 1) {
      this.gotoPage.emit({ view: BOOKING_PAGE_VIEWS.STORE_VIEW, isUserInteracted });
    } else {
      this.gotoPage.emit({ view: BOOKING_PAGE_VIEWS.SERVICES_VIEW, isUserInteracted });
    }
  }

  onMeetingTypeChange(selectedMeetingTypeId: number, isUserInteracted = true): void {
    // Reset Error Flag
    this.showMeetingTypeSelectionRequiredError = false;

    if (isUserInteracted) {
      // trigger start event on first user interaction happen and
      // meeting type selection step is configured to first step and
      // meeting types are more then 1
      this.bookingService.triggerApoointmentStartEvent();

      if (!this.isGTMEventSent) {
        this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_meetings_start);
        this.isGTMEventSent = true;
      }
    }

    this.selectedMeetingTypeId = selectedMeetingTypeId;
    this.selectedMeetingType = this.meetingTypes.find(meeting => meeting.id === this.selectedMeetingTypeId);

    // Proceeds to the next step if the one-page UI is enabled.
    this.widgetService.widgetConf?.context?.enable_one_page_ui && this.next();
  }
}
