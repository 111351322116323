@if (widgetService.widgetUUID) {
  <link
    rel="stylesheet" type="text/css"
    [href]="('/assets/css/booking/' + widgetService.widgetConf.partner.booking_name + '_' + widgetService.widgetUUID + '.css') | calioSafe: 'resourceUrl'"
  >
}
<div class="bookings-section" [@fadeInFadeOut]="disableAnimation ? null : counter">
  @if (this.widgetTemplates?.length) {
    <div
      id="{{ bookingPageViews.BOOKING_QUESTIONS_VIEW_BEFORE }}"
      class="cbsw-booking-questions-view-before"
      [ngClass]="{
        'cbsw-not-allow-edit': !allowEditActivePage(bookingPageViews.BOOKING_QUESTIONS_VIEW_BEFORE),
        'cbsw-hide-section': selectedViewPrecedence > showOnePageUiUpToViewPrecedence && selectedViewPrecedence !== sectionPrecedence[bookingPageViews.BOOKING_QUESTIONS_VIEW_BEFORE],
        'cbsw-active-section': selectedView === bookingPageViews.BOOKING_QUESTIONS_VIEW_BEFORE,
      }"
    >
      @if (
        (selectedView === bookingPageViews.BOOKING_QUESTIONS_VIEW_BEFORE || showOnePageUI(bookingPageViews.BOOKING_QUESTIONS_VIEW_BEFORE)) &&
        widgetService?.widgetConf?.context?.show_questions_before_booking_process
      ) {

        <app-booking-questions-view
          customClass="cbsw-booking-questions-step-before"
          [isFirstBookingQuestionActive]="isFirstBookingQuestionActive"
          [isLastBookingQuestionActive]="isLastBookingQuestionActive"
          (nextPageEvent)="increment(); isFirstBookingQuestionActive = false; isLastBookingQuestionActive = true; gotoView($event); bookingService.lastView = bookingPageViews.BOOKING_QUESTIONS_VIEW_BEFORE"
        />
      }
    </div>

    <div
      id="{{ bookingPageViews.MEETING_TYPE_VIEW }}"
      class="cbsw-meeting-type-view"
      [ngClass]="{
        'cbsw-not-allow-edit': !allowEditActivePage(bookingPageViews.MEETING_TYPE_VIEW),
        'cbsw-hide-section': selectedViewPrecedence > showOnePageUiUpToViewPrecedence && selectedViewPrecedence !== sectionPrecedence[bookingPageViews.MEETING_TYPE_VIEW],
        'cbsw-active-section': selectedView === bookingPageViews.MEETING_TYPE_VIEW,
      }"
    >
      @if (
        (selectedView === bookingPageViews.MEETING_TYPE_VIEW || showOnePageUI(bookingPageViews.MEETING_TYPE_VIEW)) &&
        widgetService?.widgetConf?.context?.show_meeting_type_picker
      ) {
        <app-meeting-type-selection
          (gotoPage)="increment(); gotoView($event); bookingService.lastView = bookingPageViews.MEETING_TYPE_VIEW"
          (previousPageEvent)="decrement(); gotoView($event); bookingService.lastView = bookingPageViews.MEETING_TYPE_VIEW"
        />
      }
    </div>

    <div
      id="{{ bookingPageViews.STORE_VIEW }}"
      class="cbsw-store-view"
      [ngClass]="{
        'cbsw-not-allow-edit': !allowEditActivePage(bookingPageViews.STORE_VIEW),
        'cbsw-hide-section': selectedViewPrecedence > showOnePageUiUpToViewPrecedence && selectedViewPrecedence !== sectionPrecedence[bookingPageViews.STORE_VIEW],
        'cbsw-active-section': selectedView === bookingPageViews.STORE_VIEW,
      }"
    >
      @if (
        (selectedView === bookingPageViews.STORE_VIEW || showOnePageUI(bookingPageViews.STORE_VIEW)) &&
        (
          !widgetService?.widgetConf?.context?.show_meeting_type_picker ||
          (
            widgetService?.widgetConf?.context?.show_meeting_type_picker &&
            bookingService?.selectedBookingData?.currentSelectedAppointmentState?.selectedMeetingTypeId === 1
          )
        )
      ) {
        <app-store-selection
          (gotoPage)="increment(); gotoView($event); bookingService.lastView = bookingPageViews.STORE_VIEW"
          (previousPageEvent)="decrement(); gotoView($event); bookingService.lastView = bookingPageViews.STORE_VIEW"
        />
      }
    </div>

    <div
      id="{{ bookingPageViews.SERVICES_VIEW }}"
      class="cbsw-service-view"
      [ngClass]="{
        'cbsw-not-allow-edit': !allowEditActivePage(bookingPageViews.SERVICES_VIEW),
        'cbsw-hide-section': selectedViewPrecedence > showOnePageUiUpToViewPrecedence && selectedViewPrecedence !== sectionPrecedence[bookingPageViews.SERVICES_VIEW],
        'cbsw-active-section': selectedView === bookingPageViews.SERVICES_VIEW
      }"
    >
      @if (selectedView === bookingPageViews.SERVICES_VIEW || showOnePageUI(bookingPageViews.SERVICES_VIEW)) {
        <app-services-selection
          (nextPageEvent)="increment(); gotoView($event); bookingService.lastView = bookingPageViews.SERVICES_VIEW"
          (previousPageEvent)="decrement(); gotoView($event); bookingService.lastView = bookingPageViews.SERVICES_VIEW"
        />
      }
    </div>
    <div
      id="{{ bookingPageViews.WORKERS_VIEW }}"
      class="cbsw-workers-view"
      [ngClass]="{
        'cbsw-not-allow-edit': !allowEditActivePage(bookingPageViews.WORKERS_VIEW),
        'cbsw-hide-section': selectedViewPrecedence > showOnePageUiUpToViewPrecedence && selectedViewPrecedence !== sectionPrecedence[bookingPageViews.WORKERS_VIEW],
        'cbsw-active-section': selectedView === bookingPageViews.WORKERS_VIEW
      }"
    >
      @if (selectedView === bookingPageViews.WORKERS_VIEW || showOnePageUI(bookingPageViews.WORKERS_VIEW)) {
        <app-workers-selection
          (nextPageEvent)="increment(); gotoView($event); bookingService.lastView = bookingPageViews.WORKERS_VIEW"
          (previousPageEvent)="decrement(); gotoView($event); bookingService.lastView = bookingPageViews.WORKERS_VIEW"
        />
      }
    </div>

    <div
      id="{{ bookingPageViews.SLOTS_VIEW }}"
      class="cbsw-slots-view"
      [ngClass]="{
        'cbsw-not-allow-edit': !allowEditActivePage(bookingPageViews.SLOTS_VIEW),
        'cbsw-hide-section': selectedViewPrecedence > showOnePageUiUpToViewPrecedence && selectedViewPrecedence !== sectionPrecedence[bookingPageViews.SLOTS_VIEW],
        'cbsw-active-section': selectedView === bookingPageViews.SLOTS_VIEW
      }"
    >
      @if (selectedView === bookingPageViews.SLOTS_VIEW || showOnePageUI(bookingPageViews.SLOTS_VIEW)) {
        <app-slots-selection
          (nextPageEvent)="increment(); gotoView($event); bookingService.lastView = bookingPageViews.SLOTS_VIEW"
          (previousPageEvent)="decrement(); gotoView($event); bookingService.lastView = bookingPageViews.SLOTS_VIEW"
        />
      }
    </div>

    <div
      id="{{ bookingPageViews.BOOKING_QUESTIONS_VIEW_AFTER }}"
      class="cbsw-booking-questions-view-after"
      [ngClass]="{
        'cbsw-not-allow-edit': !allowEditActivePage(bookingPageViews.BOOKING_QUESTIONS_VIEW_AFTER),
        'cbsw-hide-section': selectedViewPrecedence > showOnePageUiUpToViewPrecedence && selectedViewPrecedence !== sectionPrecedence[bookingPageViews.BOOKING_QUESTIONS_VIEW_AFTER],
        'cbsw-active-section': selectedView === bookingPageViews.BOOKING_QUESTIONS_VIEW_AFTER
      }"
    >
      @if (
        (selectedView === bookingPageViews.BOOKING_QUESTIONS_VIEW_AFTER || showOnePageUI(bookingPageViews.BOOKING_QUESTIONS_VIEW_AFTER)) &&
        !widgetService?.widgetConf?.context?.show_questions_before_booking_process
      ) {
        <app-booking-questions-view
          customClass="cbsw-booking-questions-step-after"
          [isFirstBookingQuestionActive]="isFirstBookingQuestionActive"
          [isLastBookingQuestionActive]="isLastBookingQuestionActive"
          (nextPageEvent)="increment(); isFirstBookingQuestionActive = false; isLastBookingQuestionActive = true; gotoView($event)"
          (previousPageEvent)="decrement(); isFirstBookingQuestionActive = true; isLastBookingQuestionActive = false; gotoView($event)"
        />
      }
    </div>

    <div
      id="{{ bookingPageViews.PERSONAL_FORM_VIEW }}"
      class="cbsw-personal-form-view"
      [ngClass]="{
        'cbsw-not-allow-edit': !allowEditActivePage(bookingPageViews.PERSONAL_FORM_VIEW),
        'cbsw-hide-section': selectedViewPrecedence > showOnePageUiUpToViewPrecedence && selectedViewPrecedence !== sectionPrecedence[bookingPageViews.PERSONAL_FORM_VIEW],
        'cbsw-active-section': selectedView === bookingPageViews.PERSONAL_FORM_VIEW
      }"
    >
      @if (selectedView === bookingPageViews.PERSONAL_FORM_VIEW || showOnePageUI(bookingPageViews.PERSONAL_FORM_VIEW)) {
        <app-personal-form-view
          (nextPageEvent)="increment(); gotoView($event)"
          (previousPageEvent)="decrement(); isFirstBookingQuestionActive = false; isLastBookingQuestionActive = true; gotoView($event);"
          [partnerData]="bookingService?.partnerData"
        />
      }
    </div>

    <div
      id="{{ bookingPageViews.BOOKING_SUMMARY_VIEW }}"
      class="cbsw-booking-summary-view"
      [ngClass]="{
        'cbsw-not-allow-edit': !allowEditActivePage(bookingPageViews.BOOKING_SUMMARY_VIEW),
        'cbsw-hide-section': selectedViewPrecedence > showOnePageUiUpToViewPrecedence && selectedViewPrecedence !== sectionPrecedence[bookingPageViews.BOOKING_SUMMARY_VIEW],
        'cbsw-active-section': selectedView === bookingPageViews.BOOKING_SUMMARY_VIEW
      }"
    >
      @if (selectedView === bookingPageViews.BOOKING_SUMMARY_VIEW || showOnePageUI(bookingPageViews.BOOKING_SUMMARY_VIEW)) {
        <app-booking-summary-view
          id="BOOKING_SUMMARY_VIEW"
          (nextPageEvent)="increment(); gotoView($event)"
          (previousPageEvent)="decrement(); gotoView($event)"
          (gotoPageEvent)="gotoView($event)"
        />
      }
    </div>

    <div
      id="{{ bookingPageViews.BOOKING_SUCCESS_VIEW }}"
      class="cbsw-booking-success-view"
      [ngClass]="{
        'cbsw-not-allow-edit': !allowEditActivePage(bookingPageViews.BOOKING_SUCCESS_VIEW),
        'cbsw-hide-section': selectedViewPrecedence > showOnePageUiUpToViewPrecedence && selectedViewPrecedence !== sectionPrecedence[bookingPageViews.BOOKING_SUCCESS_VIEW],
        'cbsw-active-section': selectedView === bookingPageViews.BOOKING_SUCCESS_VIEW
      }"
    >
      @if (selectedView === bookingPageViews.BOOKING_SUCCESS_VIEW || showOnePageUI(bookingPageViews.BOOKING_SUCCESS_VIEW)) {
        <app-booking-success-view
          id="BOOKING_SUCCESS_VIEW"
          (nextPageEvent)="increment(); gotoView($event)"
          (previousPageEvent)="decrement(); gotoView($event)"
        />
      }
    </div>

    <div
      id="{{ bookingPageViews.BOOKING_FAILED_VIEW }}"
      class="cbsw-booking-failed-view"
      [ngClass]="{
        'cbsw-not-allow-edit': !allowEditActivePage(bookingPageViews.BOOKING_FAILED_VIEW),
        'cbsw-hide-section': selectedViewPrecedence > showOnePageUiUpToViewPrecedence && selectedViewPrecedence !== sectionPrecedence[bookingPageViews.BOOKING_FAILED_VIEW],
        'cbsw-active-section': selectedView === bookingPageViews.BOOKING_FAILED_VIEW
      }"
    >
      @if (selectedView === bookingPageViews.BOOKING_FAILED_VIEW || showOnePageUI(bookingPageViews.BOOKING_FAILED_VIEW)) {
        <app-booking-failed-view
          id="BOOKING_FAILED_VIEW"
          (nextPageEvent)="increment(); gotoView($event)"
        />
      }
    </div>
  }
</div>
@if (bookingService?.partnerData?.analytics?.GTM && bookingService?.partnerData?.analytics?.GTM.trim() !== '') {
  <div>
    <app-gtm-operation [gtmID]="bookingService?.partnerData?.analytics?.GTM" />
  </div>
}
