import { NgClass, NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { DATE_ISO_FORMAT, TIME_FORMAT } from '@constants/date.constants';
import { AppointmentServiceModel } from '@models/appointment-service.model';
import { FreeAppointmentDao } from '@models/availability.model';
import { AppointmentCartItem } from '@models/selected-booking-data.model';
import { WidgetAppointmentGroupModel } from '@models/widget-appointment-group.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CalioDateUtcPipe } from '@pipes/calio-date-utc.pipe';
import { CalioTranslationPipe } from '@pipes/calio-translation.pipe';
import { BookingService } from '@services/feature/booking.service';
import { CustomEventService } from '@services/feature/custom-event.service';
import { WidgetService } from '@services/feature/widget.service';
import { DateUtilService } from '@services/utils/date-util.service';
import { LoggerService } from '@services/utils/logger.service';
import { InfoCardComponent } from '@ui-lib/cards/info-card/info-card.component';
import { CswLinkComponent } from '@ui-lib/typography/csw-link/csw-link.component';

@Component({
  selector: 'app-slots-card-selection',
  templateUrl: './slots-card-selection.component.html',
  styleUrls: ['./slots-card-selection.component.scss'],
  standalone: true,
  imports: [MatTabsModule, NgTemplateOutlet, InfoCardComponent, MatCheckboxModule, NgClass, CswLinkComponent, TranslateModule, CalioTranslationPipe, CalioDateUtcPipe]
})
export class SlotsCardSelectionComponent implements OnInit, AfterViewInit {

  readonly dateIsoFormat = DATE_ISO_FORMAT;
  readonly timeFormat = TIME_FORMAT;

  @Input() freeAppointments: {
    dayPeriod: string,
    appointments: FreeAppointmentDao[],
    showDayPeriodLabel: boolean,
    widgetGroup: WidgetAppointmentGroupModel,
    loadMore?: boolean
    selectedSlots?: number
  }[];
  @Input() freeAppointmentsCount = 0;
  @Input() noAppointments: boolean;

  @Output() gotoNextPageEvent: EventEmitter<any> = new EventEmitter<any>();

  lang: string;
  hasGroups = false;
  selectedWorkerId = 0;

  constructor(
    public translate: TranslateService,
    private customEventService: CustomEventService,
    public widgetService: WidgetService,
    private bookingService: BookingService,
    private dateUtilService: DateUtilService,
    private _renderer: Renderer2
  ) {
    this.lang = this.translate.getDefaultLang();
  }

  ngOnInit(): void {
    this.selectedWorkerId = this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedWorkerId;
    this.freeAppointments.forEach(freeAppointmentGroup => {
      freeAppointmentGroup.loadMore = false;
      freeAppointmentGroup.selectedSlots = 0;
      if (freeAppointmentGroup.widgetGroup) {
        this.hasGroups = true;
      }
    });

    this.customEventService.resetSlotSettingsEvent.subscribe({
      next: () => {
        this.freeAppointments.forEach(freeAppointmentGroup => {
          freeAppointmentGroup.loadMore = false;
          freeAppointmentGroup.selectedSlots = 0;
        });
      }
    });
    this.bookingService.loadAppointmentCartItemsFromLocalStorage();

    LoggerService.log(
      'this.bookingService.selectedBookingData.cartItems ',
      this.bookingService.selectedBookingData.cartItems
    );

    if (this.bookingService.selectedBookingData?.cartItems?.length > 0) {
      for (const cartItem of this.bookingService.selectedBookingData.cartItems) {
        for (const service of this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServices) {
          for (const appointmentsGroup of this.freeAppointments) {
            for (const appointment of appointmentsGroup.appointments) {
              if (this.createCartItemId(service, appointment) === cartItem.cartItemId) {
                appointment.selected = true;
              }
            }
          }
        }
      }
      this.disableAndEnableAppointmentSlots();
    }

  }

  ngAfterViewInit(): void {
    this.updateTabsCounter();
    const tabInkBar = document.querySelector('.mdc-tab-indicator__content--underline');
    if (tabInkBar) {
      this._renderer.addClass(tabInkBar, 'csw-slot-selected-tab');
      if (this.widgetService?.widgetConf?.header_background_color) {
        this._renderer.setStyle(tabInkBar, 'border-color', this.widgetService?.widgetConf?.header_background_color);
      }
    }
  }

  onSelectAppointment(event: MatCheckboxChange, appointment: FreeAppointmentDao): void {
    if (event.checked) {
      this.addToCart(appointment);
    } else {
      this.removeFromCart(appointment);
    }
    this.customEventService.refreshCalendarBadgeInBookingEvent.emit({
      cartItems: this.bookingService.selectedBookingData.cartItems
    });

    this.updateTabsCounter();
  }

  updateTabsCounter(): void {
    this.freeAppointments.forEach(freeAppointmentGroup => {
      const selectedAppointments = freeAppointmentGroup.appointments.filter(appointment => appointment?.selected === true);
      freeAppointmentGroup.selectedSlots = (selectedAppointments?.length ? selectedAppointments.length : 0);
    });
  }

  loadMore(loadMoreIndex: number): void {
    this.freeAppointments.forEach((freeAppointmentGroup, index) => {
      if (loadMoreIndex === index) {
        freeAppointmentGroup.loadMore = true;
        return;
      }
    });
  }

  gotoNextPage(): void {
    this.gotoNextPageEvent.emit();
  }

  private addToCart(appointment: FreeAppointmentDao): void {
    if (this.bookingService.partnerData.is_cart_supported === 1) {
      // Multiple Slots
      for (const service of this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServices) {
        this.bookingService.selectedBookingData.cartItems.push(
          this.createAppointmentCartItem(appointment, service)
        );
      }
    } else {
      // Single Slots
      this.bookingService.selectedBookingData.cartItems = [];
      for (const item of this.freeAppointments) {
        for (const tempAppointment of item.appointments) {
          tempAppointment.selected = false;
        }
      }
      for (const service of this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServices) {
        this.bookingService.selectedBookingData.cartItems.push(
          this.createAppointmentCartItem(appointment, service)
        );
      }
    }

    appointment.selected = true;
    this.disableAndEnableAppointmentSlots();

    LoggerService.log('After adding this.bookingService.selectedBookingData.cartItems ',
      this.bookingService.selectedBookingData.cartItems);
    this.bookingService.setAppointmentCartItemsInLocalStorage(this.bookingService.selectedBookingData.cartItems);
    this.customEventService.refreshCalendarBadgeInBookingEvent.emit({
      cartItems: this.bookingService.selectedBookingData.cartItems
    });
  }

  private removeFromCart(appointment: FreeAppointmentDao): void {
    const removeCartItemIds: string[] = [];
    for (const service of this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServices) {
      removeCartItemIds.push(this.createCartItemId(service, appointment));
    }

    this.bookingService.selectedBookingData.cartItems = this.bookingService.selectedBookingData.cartItems
      .filter(cartItem => removeCartItemIds.indexOf(cartItem.cartItemId) === -1);

    appointment.selected = false;
    this.disableAndEnableAppointmentSlots();

    this.bookingService.setAppointmentCartItemsInLocalStorage(this.bookingService.selectedBookingData.cartItems);
    this.customEventService.refreshCalendarBadgeInBookingEvent.emit({
      cartItems: this.bookingService.selectedBookingData.cartItems
    });
  }

  private disableAndEnableAppointmentSlots(): void {
    for (const appointmentsGroup of this.freeAppointments) {
      for (const appointment of appointmentsGroup.appointments) {
        appointment.disabled = this.slotsConflict(appointment);
      }
    }
  }

  private slotsConflict(appointment: FreeAppointmentDao): boolean {
    // Iterate cart items
    for (const cartItem of this.bookingService.selectedBookingData.cartItems) {
      if (
        new Date(appointment.start) > new Date(cartItem.startTime) &&
        new Date(appointment.start) < new Date(cartItem.endTime) ||
        new Date(appointment.start) < new Date(cartItem.startTime) &&
        new Date(appointment.end) > new Date(cartItem.startTime)
      ) {
        if (appointment.worker && appointment.worker.id === cartItem.workerId) {
          return true;
        }
      }
    }
    return false;
  }

  private createAppointmentCartItem(appointment: FreeAppointmentDao, service: AppointmentServiceModel): AppointmentCartItem {
    const cartItem = new AppointmentCartItem();
    cartItem.cartItemId = this.createCartItemId(service, appointment);
    cartItem.storeId = this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreId;
    cartItem.store = this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStore;
    cartItem.date = this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedDate;
    cartItem.startTime = appointment.start;
    cartItem.endTime = appointment.end;
    cartItem.shortStart = appointment.short_start;
    cartItem.startHour = appointment.startHour;
    cartItem.workerId = appointment.worker.id;
    cartItem.worker = appointment.worker;
    cartItem.serviceId = service.id;
    cartItem.service = service;
    cartItem.workerUuids = appointment.workerUuids;
    cartItem.isAppointmentReserved = false;
    cartItem.selectedMeetingTypeId = service.meeting_provider_id;
    return cartItem;
  }

  private createCartItemId(service: AppointmentServiceModel, appointment: FreeAppointmentDao): string {
    const storeId = this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedStoreId;
    const workerId = appointment.worker.id;
    const dateString = this.dateUtilService.getFormattedDateByFormat(
      this.bookingService.selectedBookingData.currentSelectedAppointmentState.selectedDate,
      this.dateIsoFormat
    );
    return `${storeId}-${workerId}-${service.id}-${service.meeting_provider_id}-${dateString}-${appointment.short_start}`;
  }
}
