<div class="cbsw-booking-summary-step" #bookingSummaryView>
  @if (bookingService.selectedBookingData?.cartItems?.length > 0) {
    <div class="mb-2">
      <app-csw-text
        [text]="templateTitle | translation: 'template_content': lang"
        customClass="step-element-title cbsw-booking-summary-step-title fw-600"
      />
      <div class="text-muted cbsw-booking-summary-step-subtitle">
        {{ templateSubTitle | translation: 'template_content': lang }}
      </div>
    </div>
    <!-- Cart Items Starts -->
    <ul class="list-group mb-3 mt-3">
      @for (cartItem of bookingService.selectedBookingData.cartItems; track cartItem; let index = $index) {
        <li class="list-group-item d-flex justify-content-between lh-condensed">
          <div>
            <div class="my-0 list-item-title cbsw-booking-service-name" [innerHTML]="(cartItem?.service | translation: 'name' : lang) | calioSafe: 'html'"></div>
            <div class="my-0 list-item-title cbsw-booking-service-description" [innerHTML]="(cartItem?.service | translation: 'description' : lang) | calioSafe: 'html'"></div>
            <div class="text-muted description cbw-summary-date-time">{{ cartItem.date | date: 'dd.MM.y' }}
              , {{ cartItem.startTime | dateutc: "HH:mm" }} - {{ cartItem.endTime | dateutc: "HH:mm" }}
            </div>
            <div class="description text-muted cbw-summary-resource-name">{{ cartItem?.worker?.resource_name }}</div>
            @if (cartItem.selectedMeetingTypeId || bookingService.selectedBookingData.selectedUser?.meeting_type_id) {
              @if ((bookingService.widgetTemplates | calioMeetingTemplate: {
                id: cartItem.selectedMeetingTypeId || bookingService.selectedBookingData.selectedUser?.meeting_type_id,
                type: 'meeting_template' }); as widgetTemplate
              ) {
                <div
                  class="description text-muted cbw-summary-meeting-type"
                  [innerHTML]="(widgetTemplate.titleTemplate | translation: 'template_content' : lang) | calioSafe: 'html'"
                ></div>
              }
              @if (cartItem.selectedMeetingTypeId === 1 || bookingService.selectedBookingData.selectedUser?.meeting_type_id === 1) {
                <ng-container [ngTemplateOutlet]="inStoreMeetingTypeLocation" />
              }
            } @else {
              @if ((bookingService.widgetTemplates | calioMeetingTemplate: { id: 1, type: 'meeting_template' }); as widgetTemplate) {
                <div
                  class="description text-muted cbw-summary-meeting-type"
                  [innerHTML]="(widgetTemplate.titleTemplate | translation: 'template_content' : lang) | calioSafe: 'html'"
                ></div>
              }
              <ng-container [ngTemplateOutlet]="inStoreMeetingTypeLocation" />
            }
            <ng-template #inStoreMeetingTypeLocation>
              <div class="cbw-summary-worker-store-location">
                <span class="cbw-summary-worker-store-location-name">{{ cartItem.store | translation: 'name' : lang }}</span>
                @if (cartItem.store?.is_online === 0) {
                  <span class="cbw-summary-worker-store-location-separator">,&nbsp;</span>
                  <span class="cbw-summary-worker-store-location-address">
                    @if (cartItem.store.street) {
                      {{ cartItem.store.street }},&nbsp;
                    }
                    @if (cartItem.store.zip) {
                      {{ cartItem.store.zip }}&nbsp;
                    }
                    @if (cartItem.store.city) {
                      {{ cartItem.store.city }}
                    }
                    @if (cartItem.store.country) {
                      ,&nbsp;{{ cartItem.store.country | translation: 'name' : lang }}
                    }
                  </span>
                }
              </div>
            </ng-template>
          </div>
          <div class="d-flex flex-column align-items-end justify-content-between">
            <div class="text-muted">
              @if (cartItem?.service?.price === 0) {
                {{ 'booking_components.free' | translate }}
              }
              @if (cartItem?.service?.price === -1) {
                &nbsp;
              }
              @if (cartItem?.service?.price > 0) {
                {{ cartItem.service.price | calioCurrency: bookingService.partnerData['currency'] }}
              }
            </div>
            <div class="text-danger remove-button" (click)="removeCartItem(index)">
              {{ 'booking_components.remove' | translate }}
            </div>
          </div>
        </li>
      }
      @if (subTotal > 0) {
        <li class="list-group-item d-flex justify-content-between lh-condensed">
          <div>
            <div class="my-0 list-item-title"> {{ 'booking_components.subtotal' | translate }}</div>
          </div>
          <div>
            <span class="text-muted">
              @if (subTotal === 0) {
                {{ 'booking_components.free' | translate }}
              }
              @if (subTotal === -1) {
                &nbsp;
              }
              @if (subTotal > 0) {
                {{ subTotal | calioCurrency: bookingService.partnerData['currency'] }}
              }
            </span>
          </div>
        </li>
      }
      @if (showCoupon) {
        <li class="list-group-item d-flex justify-content-between bg-light">
          <div class="text-success">
            <div class="my-0 list-item-title">
              {{ 'coupon_credit_card_info.coupon' | translate }}
            </div>
            <div class="description">
              {{ couponCode }}
            </div>
          </div>
          <div class="d-flex flex-column align-items-end justify-content-between">
            <div class="text-success">
              -{{ finalCouponDiscount | calioCurrency: bookingService.partnerData['currency'] }}
            </div>
            <div class="text-danger remove-button" (click)="removeCoupon()">
              {{ 'booking_components.remove' | translate }}
            </div>
          </div>
        </li>
      }
      @if (totalAmount > 0) {
        <li class="list-group-item d-flex justify-content-between">
          <div>
            <div class="my-0 list-item-title">{{ 'booking_components.total' | translate }}</div>
          </div>
          <strong>
            {{ totalAmount | calioCurrency: bookingService.partnerData['currency'] }}
          </strong>
        </li>
      }
    </ul>
    <app-payment-method-view
      (previousPageEvent)="previous()"
      (onAdditionalServiceEvent)="onAdditionalService()"
    />
  }
  @if (bookingService.selectedBookingData?.cartItems?.length === 0) {
    <div class="alert alert-danger">
      <div>
        {{ 'booking_components.emptyCartWarning' | translate }}
      </div>
    </div>
    @if (bookingService.partnerData.is_cart_supported === 1) {
      <div class="d-flex justify-content-center mt-4 mb-4">
        <div>
          <app-button
            id="{{ 'booking-summary-additional-service-btn' }}"
            (clickEvent)="onAdditionalService()"
            [fontColor]="widgetService?.widgetConf?.button_font_color"
            [backgroundColor]="widgetService?.widgetConf?.button_background_color"
          >
            {{ 'booking_components.add_service' | translate }}
          </app-button>
        </div>
      </div>
    }
  }
</div>
